import { ref, watch } from "vue";
import { useRoute } from 'vue-router'
import { ElMessage } from "element-plus"
import axios from 'axios';
import { LearningCourse } from "@/types/learning_course";
import { LearningForm } from "@/types/learningForm";
import { LearningCourseRepository } from '@/repositories/LearningCourseRepository'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'
// quillの画像resize系のバグでgetHTMLじゃないとちゃんとしたデータ取れないのでrefを管理する
export const LearningCourseLogic = () => {

  const learningCourseErrors = ref("");
  const learningCourseLoading = ref(false)
  const learningCourse = ref<LearningCourse>({
    id: 0,
    name: ""
  })
  const learningForms = ref<LearningForm[]>([])
  const learningFormRefs = ref<{ [index: number]: any }>({})
  const learningAnswerRefs = ref<{ [index: number]: any }>({})
  const learningCommentaryRefs = ref<{ [index: number]: any }>({})
  const route = useRoute();
  const setQuillRef = (el: any) => {
    if (el == null) {
      return;
    }
    const target_id = el.$attrs.id
    if (target_id.match(/learning_([0-9]+)/)) {
      const learning_id = target_id.match(/learning_([0-9.]+)/)[1]
      learningFormRefs.value[learning_id] = el
    }
    if (target_id.match(/answer_([0-9]+)/)) {
      const answer_id = target_id.match(/answer_([0-9.]+)/)[1]
      learningAnswerRefs.value[answer_id] = el
    }
    if (target_id.match(/commentary_([0-9]+)/)) {
      const learning_id = target_id.match(/commentary_([0-9.]+)/)[1]
      learningCommentaryRefs.value[learning_id] = el
    }
  }

  const getLearningCourse = async (projectId: number, learning_course_id: number) => {
    learningCourseLoading.value = true

    const repository: LearningCourseRepository = new LearningCourseRepository(projectId);
    try {
      learningCourse.value = await repository.get(learning_course_id);
      if (learningCourse.value.learning_forms) {
        learningForms.value = learningCourse.value.learning_forms
      }
      learningCourseLoading.value = false;
    } catch (e: any) {
      useExceptionMessage(e, "取得に失敗しました。再読み込みをしてお試し下さい");
    }
  }


  const getRandQuestion = async (formId: number, learningType: string, categoryId: number) => {
    learningCourseLoading.value = true
    const projectId = Number(route.params.project_id);
    const repository: LearningCourseRepository = new LearningCourseRepository(projectId);
    try {
      const tempLearningForm = await repository.getRandQuestion(learningType, categoryId);
      for (let i = 0; i < learningForms.value.length; i++) {
        if (learningForms.value[i].id == formId) {
          tempLearningForm.learning_id = learningForms.value[i].learning_id;
          learningForms.value[i] = tempLearningForm
          break;
        }
      }

      learningCourseLoading.value = false;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        if (e.response.status == 404) {
          learningCourseErrors.value = `作成に失敗しました。自動生成するために十分な数のFAQがありません`;
          learningCourseLoading.value = false;

        } else {
          useExceptionMessage(e, "取得に失敗しました。再読み込みをしてお試し下さい");
        }
      }
    }


  };

  const addLearningForm = () => {
    const form: LearningForm = {
      id: Math.random(),
      learning_id: Math.random(),
      question: "",
      commentary: "",
      only_one_select_flg: false,
      answers: [{ id: Math.random(), correct: false, body: "" }],
      answer_count: 0,
      correct_count: 0,
    }

    learningForms.value.push(form)
  }
  const removeLearningForm = (id: number) => {
    for (let i = 0; i < learningForms.value.length; i++) {
      if (learningForms.value[i].id == id) {
        learningForms.value.splice(i, 1);
        break;
      }
    }
  }
  const removeAnswerForm = (form: LearningForm, id: number) => {
    for (let i = 0; i < form.answers.length; i++) {
      if (form.answers[i].id == id) {
        form.answers.splice(i, 1);
        break;
      }
    }
  }
  const addAnswerFrom = (form: LearningForm) => {
    form.answers.push({ id: Math.random(), correct: false, body: "" })
  }

  const create = async (): Promise<LearningCourse|false> => {
    if (checkSize() == false) {
      ElMessage({
        showClose: true,
        message: "５MB以内に収める必要があります",
        type: 'warning'
      });
      return false;
    }

    syncLearningForm()
    const project_id = Number(route.params.project_id);
    const category_id = Number(route.params.category_id);
    const repository: LearningCourseRepository = new LearningCourseRepository(project_id);
    try {
      learningCourseLoading.value = true
      const savedLearningCourse = await repository.add(learningCourse.value.name, category_id, learningForms.value)
      learningCourseLoading.value = false
      ElMessage({
        showClose: true,
        message: "保存しました",
        type: 'success'
      });
      return savedLearningCourse;
    } catch (e: any) {
      useExceptionMessage(e, "失敗しました。再読み込みをしてお試し下さい");
      return false
    }
  }
  const checkSize = () => {
    let size = 0.0
    for (let i = 0; i < learningForms.value.length; i++) {
      const learning_id = learningForms.value[i].learning_id
      if (learningFormRefs.value[learning_id]) {
        size += learningFormRefs.value[learning_id].getHTML().length

      }
      if (learningCommentaryRefs.value[learning_id]) {
        size += learningCommentaryRefs.value[learning_id].getHTML().length
      }
      for (let j = 0; j < learningForms.value[i].answers.length; j++) {
        const answer_id = learningForms.value[i].answers[j].id
        if (learningAnswerRefs.value[answer_id]) {
          size += learningAnswerRefs.value[answer_id].getHTML().length
        }

      }
    }
    size = size / 1024 / 1024
    // 5MBにおさえる。それを超えたらエラーにする
    if (size > 5) {
      return false
    }
    return true
  }
  const syncLearningForm = () => {
    for (let i = 0; i < learningForms.value.length; i++) {
      const learning_id = learningForms.value[i].learning_id
      if (learningFormRefs.value[learning_id]) {
        learningForms.value[i].question = learningFormRefs.value[learning_id].getHTML();

      }
      if (learningCommentaryRefs.value[learning_id]) {
        learningForms.value[i].commentary = learningCommentaryRefs.value[learning_id].getHTML();
      }
      for (let j = 0; j < learningForms.value[i].answers.length; j++) {
        const answer_id = learningForms.value[i].answers[j].id
        if (learningAnswerRefs.value[answer_id]) {
          learningForms.value[i].answers[j].body = learningAnswerRefs.value[answer_id].getHTML()
        }

      }
    }
  }
  const update = async () => {
    if (checkSize() == false) {
      ElMessage({
        showClose: true,
        message: '登録するトレーニングのサイズは5MBまでになります',
        type: 'warning'
      });
      return;

    }
    syncLearningForm()
    const project_id = Number(route.params.project_id);
    const repository: LearningCourseRepository = new LearningCourseRepository(project_id);
    try {
      learningCourseLoading.value = true
      learningCourse.value = await repository.update(learningCourse.value.id, learningCourse.value.name, learningForms.value)
      if (learningCourse.value.learning_forms) {
        learningForms.value = []
        learningForms.value = learningCourse.value.learning_forms
      }
      learningCourseLoading.value = false
      ElMessage({
        showClose: true,
        message: "保存しました",
        type: 'success'
      });

      return true;
    } catch (e: any) {
      useExceptionMessage(e, "失敗しました。再読み込みをしてお試し下さい");

      return false;
    }
  }
  const deleteLearningCourse = async (learning_course_id: number) => {
    const project_id = Number(route.params.project_id);
    const repository: LearningCourseRepository = new LearningCourseRepository(project_id);
    try {
      learningCourseLoading.value = true
      await repository.deleteLearningCourse(learning_course_id)
      learningCourseLoading.value = false
    } catch (e: any) {
      useExceptionMessage(e, "失敗しました。再読み込みをしてお試し下さい");
    }

  }
  const up = (learning_id: number) => {
    for (let i = 0; i < learningForms.value.length; i++) {
      if (learningForms.value[i].learning_id == learning_id) {
        if (i == 0) {
          break;
        }
        learningForms.value.splice(i - 1, 2, learningForms.value[i], learningForms.value[i - 1]);
        break;
      }
    }
  }
  const down = (learning_id: number) => {
    for (let i = 0; i < learningForms.value.length; i++) {
      if (learningForms.value[i].learning_id == learning_id) {
        if (i == learningForms.value.length - 1) {
          break;
        }
        learningForms.value.splice(i, 2, learningForms.value[i + 1], learningForms.value[i]);
        break;
      }
    }
  }
  const LearningCourseStateReturn = () => {
    return {
      learningCourse,
      learningForms,
      learningCourseLoading,
      learningCourseErrors,
      syncLearningForm,
      getLearningCourse,
      getRandQuestion,
      addLearningForm,
      removeLearningForm,
      addAnswerFrom,
      removeAnswerForm,
      create,
      update,
      up,
      down,
      deleteLearningCourse,
      setQuillRef
    }
  }
  watch(learningCourseErrors, () => {
    if (learningCourseErrors.value == "") {
      return;
    }
    ElMessage({
      showClose: true,
      message: learningCourseErrors.value,
      type: 'error'
    });
    learningCourseErrors.value = "";
  })
  return {
    LearningCourseStateReturn,
    ...LearningCourseStateReturn()
  }
}


