import { ref } from "vue";
// import axios from "axios";
import { QusitionState } from "@/state/QusitionState";
import { QuestionCategory } from '@/types/question_category'
import { QuestionCategoryRepository } from '@/repositories/QuestionCategoryRepository'

export const QuestionCategoryLogic = () => {
    const questionCategoryErrors = ref("");
    const questionCategoryLoading = ref(false)
    const loadQuestionCategories = async () => {
        questionCategoryLoading.value = true;
        try {
            const repository = new QuestionCategoryRepository(QusitionState.state.projectId);
            const questionCategories: QuestionCategory[] = await repository.getList();
            QusitionState.setQuestionCategories(questionCategories)
            questionCategoryLoading.value = false;
        } catch {
            questionCategoryErrors.value = "カテゴリ取得時にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    };
    const addQuestionCategory = async (parentId: number, name: string) => {
        questionCategoryLoading.value = true;
        try {
            const repository = new QuestionCategoryRepository(QusitionState.state.projectId);
            const questionCategories: QuestionCategory[] = await repository.add(parentId, name);
            QusitionState.setQuestionCategories(questionCategories)
            questionCategoryLoading.value = false;
        } catch {
            questionCategoryErrors.value = "カテゴリ追加にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }
    const updateQuestionCategory = async (targetId: number, name: string) => {
        questionCategoryLoading.value = true;
        try {
            const repository = new QuestionCategoryRepository(QusitionState.state.projectId);
            const questionCategories: QuestionCategory[] = await repository.update(targetId, name);
            QusitionState.setQuestionCategories(questionCategories)
            questionCategoryLoading.value = false;
        } catch {
            questionCategoryErrors.value = "更新にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }

    const deleteQuestionCategory = async (targetId: number) => {
        questionCategoryLoading.value = true;
        const repository = new QuestionCategoryRepository(QusitionState.state.projectId);
        try {
            const questionCategories: QuestionCategory[] = await repository.delete(targetId)
            QusitionState.setQuestionCategories(questionCategories)
            questionCategoryLoading.value = false;
        } catch {
            questionCategoryErrors.value = "削除時にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }
    const sortQuestionCategory = async (targetId: number, dependId: number, type: string) => {
        questionCategoryLoading.value = true;
        const repository = new QuestionCategoryRepository(QusitionState.state.projectId);
        try {
            const questionCategories: QuestionCategory[] = await repository.sort(targetId, dependId, type)
            QusitionState.setQuestionCategories(questionCategories)
            questionCategoryLoading.value = false;
        } catch {
            questionCategoryErrors.value = "並び替え時にエラーが発生しました。再読み込みをしてお試し下さい";
        }
    }
    const questionCategoryStateReturn = () => {
        return {
            loadQuestionCategories,
            addQuestionCategory,
            updateQuestionCategory,
            deleteQuestionCategory,
            sortQuestionCategory,
            questionCategoryErrors,
            questionCategoryLoading

        }
    }

    return {
        questionCategoryStateReturn,
        ...questionCategoryStateReturn()
    }
}


